// Include required parts of theme
//
// Adjust the `default/` path with theme folder that you used (e.g. dark/variables)

@import "default/variable-bs-overrides";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "default/variables";
@import "default/mixins";
// your own variables
@import "custom/variable-custom";


/* put your own styles to customize and override the theme */
#user-dropdown {
	left: -75px;

	.dropdown-arrow {
		left: 6.2rem;
	}
}


#dropdown-toggle-datepicker {
	width: 150px;
}

#dropdown-datepicker {
	left: -8rem !important;
	top: 2.3rem !important;

	.dropdown-arrow {
		right: 1rem;
	}
}

.auth-header {
	background-color: #fb263b;
}

td {
	.form-group {
		margin-bottom: 0px;
	}
}


.fc-toolbar.fc-header-toolbar {
	margin-bottom: 0rem !important;
}

.fc-day-number {
	float: right;
	padding: 2px;
	padding-right: .5rem;
}


.page {
	min-height: 900px;
}


.fc-scroller.fc-day-grid-container {
	overflow: hidden hidden !important;
}

.fc-event {
	cursor: pointer;
}


.table-hover tr {
	cursor: pointer;
}